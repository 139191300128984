import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostCard from "../components/post-card/post-card";
import SEO from "../components/seo";
import { TagPostsWrapper, TagPageHeading, TagName } from "./templates.style";

const Tags = ({ pageContext, data }: any) => {
	const { tag } = pageContext;
	const { edges, totalCount } = data.allWpPost;
	console.log("*******************************");
	console.log(tag);
	console.dir(edges);
	console.log(totalCount);
	return (
		<Layout>
			<SEO
				title={tag}
				description={`A collection of ${totalCount} post`}
			/>

			<TagPostsWrapper>
				<TagPageHeading>
					<TagName>{tag}</TagName>
					{`A collection of ${totalCount} post`}
				</TagPageHeading>
				{edges.map(({ node, index }: any) => (
					<PostCard
						title={node.title}
						url={node.uri}
						key={node.uri}
						description={node.excerpt}
						date={node.date}
						image={
							node.featuredImage == null
								? null
								: node.featuredImage.node.localFile
										.childImageSharp.gatsbyImageData
						}
						tags={node.tags.nodes.map((tag: any) => {
							return tag.name;
						})}
					/>
				))}
			</TagPostsWrapper>
		</Layout>
	);
};

export default Tags;

export const pageQuery = graphql`
	query ($tag: [String]) {
		allWpPost(
			limit: 2000
			sort: { fields: date, order: DESC }
			filter: { tags: { nodes: { elemMatch: { name: { in: $tag } } } } }
		) {
			totalCount
			edges {
				node {
					excerpt
					title
					tags {
						nodes {
							name
						}
					}
					date(formatString: "DD [<span>] MMMM [</span>]")
					uri
					featuredImage {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										quality: 100
										sizes: "400"
										placeholder: BLURRED
										layout: FULL_WIDTH
										formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
				}
			}
		}
	}
`;
