import * as React from "react";
import { Link } from "gatsby";
import _ from "lodash";
import GatsbyImage from "../gatsby-image";
import {
	PostCardWrapper,
	PostPreview,
	PostDetails,
	PostDate,
	PostTitle,
	Excerpt,
	PostContent,
	PostTags,
} from "./post-card.style";

interface PostCardProps {
	image?: any;
	title: string;
	description?: string;
	url: string;
	modified?: string;
	tags?: [];
	className?: string;
	placeholderBG?: string;
}

const PostCard: React.FunctionComponent<PostCardProps> = ({
	image,
	title,
	description,
	url,
	modified,
	tags,
	className,
	placeholderBG,
	...props
}) => {
	// Add all classs to an array
	const addAllClasses = ["post_card"];

	// className prop checking
	if (className) {
		addAllClasses.push(className);
	}
	// console.log(image);
	// console.log(title);
	// console.log(description);
	// console.log(url);
	// console.log(modified);
	// console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
	// console.log(tags);

	return (
		<PostCardWrapper className={addAllClasses.join(" ")} {...props}>
			{image == null ? null : (
				<PostPreview className="post_preview">
					<Link to={url}>
						<GatsbyImage
							src={image}
							alt="post preview"
							backgroundColor={placeholderBG}
						/>
					</Link>
				</PostPreview>
			)}

			<PostDetails className="post_details">
				<PostContent className="post_content">
					<PostTitle className="post_title">
						<Link to={url}>
							{title.length < 50
								? title
								: `${title.slice(0, 49)}…`}
						</Link>
					</PostTitle>
					{/* {description && (
						<Excerpt
							dangerouslySetInnerHTML={{
								__html: description,
							}}
							className="excerpt"
						/>
					)} */}

					{/* {modified && (
						<PostDate
							dangerouslySetInnerHTML={{
								__html: modified,
							}}
							className="post_date"
						/>
					)} */}
					{tags == null ? null : (
						<PostTags className="post_tags">
							{tags.map((tag: string, index: number) => (
								<Link
									key={index}
									to={`/tags/${_.kebabCase(tag)}/`}
								>
									{`#${tag}`}
								</Link>
							))}
						</PostTags>
					)}
				</PostContent>
			</PostDetails>
		</PostCardWrapper>
	);
};

export default PostCard;
