import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const PostCardWrapper = styled.div`
	/* position: relative; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 60px;
	width: 380px;
	@media (max-width: 1239px) {
		width: 190px;
		margin-bottom: 50px;
	}
	@media (max-width: 1023px) {
		width: 170px;
		margin-bottom: 50px;
	}
	@media (max-width: 767px) {
		width: 190px;
		margin-bottom: 50px;
	}
	@media (max-width: 424px) {
		width: 170px;
		margin-bottom: 50px;
	}
	@media (max-width: 374px) {
		width: 145px;
		margin-bottom: 40px;
	}
`;

export const PostPreview = styled.div`
	/* margin-bottom: 10px; */
	/* position: relative; */
	img {
		/* border-radius: 3px; */
		object-fit: cover;
		height: 237.5px;

		@media (max-width: 1239px) {
			height: 118.75px;
		}
		@media (max-width: 1023px) {
			height: 106.75px;
		}
		@media (max-width: 767px) {
			height: 118.75px;
		}
		@media (max-width: 424px) {
			height: 106.25px;
		}
		@media (max-width: 374px) {
			height: 90.62px;
		}
	}

	/* &:before {
		content: "";
		position: absolute;
		width: 80%;
		height: 80%;
		background-color: #818181;
		bottom: 0;
		left: 10%;
		filter: blur(13px);
	} */
`;

export const PostDetails = styled.div`
	display: flex;
`;

export const PostDate = styled.div`
	color: ${themeGet("colors.baseColorDark")};
	font-weight: 800;
	text-transform: uppercase;
	@media (max-width: 990px) {
		font-size: 12px;
	}
	@media (max-width: 575px) {
		font-size: 11px;
	}

	::before {
		margin-right: 8px;
		content: "🔁";
	}
	/* font-size: 90px; */
	/* font-weight: 700; */
	/* text-align: center; */
	/* padding: 30px 35px 15px 35px; */
	/* margin-right: 20px; */
	/* line-height: 1; */
	/* color: ${themeGet("colors.textColor", "#292929")}; */
	@media (max-width: 1200px) {
		/* font-size: 70px;
		padding: 25px 25px 15px 25px;
		margin-right: 35px; */
	}
	@media (max-width: 990px) {
		/* font-size: 56px;
		padding: 20px 20px 15px 20px;
		margin-right: 25px; */
	}
	@media (max-width: 575px) {
		/* display: none; */
	}

	> span {
		/* font-size: 13px;
		font-weight: 400;
		display: block;
		margin-top: 12px;
		text-transform: uppercase; */
	}
`;

export const PostContent = styled.div`
	align-self: center;
`;

export const PostTitle = styled.h2`
	/* font-size: 30px;
	font-weight: 700;
	color: ${themeGet("colors.textColor", "#292929")};
	line-height: 1.53;
	margin-bottom: 10px;
	a {
		color: ${themeGet("colors.textColor", "#292929")};
	}
	@media (max-width: 1200px) {
		font-size: 26px;
	}
	@media (max-width: 990px) {
		font-size: 21px;
		margin-bottom: 12px;
	}
	@media (max-width: 575px) {
		font-size: 20px;
		margin-bottom: 5px;
	} */
	a {
		color: ${themeGet("colors.textColor", "#292929")};
	}
	text-align: justify;
	line-height: 1.4;
	margin-bottom: 0.5em;
	font-size: 15px;
	height: 85px;

	@media (max-width: 1239px) {
		font-size: 14px;
		height: 80px;
	}
	@media (max-width: 424px) {
		font-size: 13px;
		height: 75px;
	}
	@media (max-width: 374px) {
		font-size: 12px;
		height: 70px;
	}
`;

export const Excerpt = styled.p`
	font-size: ${themeGet("fontSizes.3", "15")}px;
	color: ${themeGet("textColor", "#292929")};
	font-weight: 400;
	line-height: 2;
	margin-bottom: 0;
	@media (max-width: 990px) {
		font-size: 14px;
	}
`;

export const PostTags = styled.div`
	/* display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 15px;

	a {
		display: block;
		margin-right: 30px;
		font-size: 14px;
		font-weight: 400;
		color: ${themeGet("primary", "#D10068")};
		@media (max-width: 990px) {
			font-size: 13px;
			margin-right: 25px;
		}
	} */
	font-weight: 800;
	font-size: 13px;
	a {
		color: ${themeGet("primary", "#D10068")};
		margin-right: 10px;
	}
	@media (max-width: 1199px) {
		font-size: 12px;
	}
	@media (max-width: 424px) {
		font-size: 11px;
	}
	@media (max-width: 374px) {
		font-size: 10px;
	}
`;
